.contact_container{
	
		@media #{$medium-up}
		{
			background:linear-gradient(to left, transparent 50%, #F2F2F2 50%);
		}
	
	&.contact_ko{
		background:linear-gradient(to left, transparent 50%, #f15c5c 50%);

	}



	
	.situacio{
	

		padding:rem-calc(0 25);
		margin-top:2em;
		
		@media #{$medium-up}
		{
			margin-top:0;
		}
		
		@media #{$large-up}
		{
			padding:rem-calc(0 65);
		}
		
		figure{
			text-align:center;
			
			@media #{$medium-up}
			{
				text-align:left;
			}
			
		}
		
		h3{
			font-size:rem-calc(18);
			font-weight:bold;
		}
		
		li{
			margin-bottom:2em;
		}
		
		a{
			color:#000;
			text-decoration:none;
			&:hover {
				text-decoration: underline;
			}
		}
		
		
		.adreca:before{

			content:'';
			display:inline-block;
			width:rem-calc(25);
			height:rem-calc(33);
			background-color:#f00;
			background:url(img/ico-lloc-contacte.svg) no-repeat;
			
		}
		
		
	}
	
	
	
}