.main-footer {
	text-align:center;
	margin-top:rem-calc(50);
	@media #{$medium-up}
		{
			margin-top:rem-calc(150);
		}
	.social{
		background-color: $primary-color;

		ul{
			list-style:none;
			display:inline-block;
			margin:0;
			padding: rem-calc(70) 0;
		}
		
		li{
			display:inline-block;
			
			
			@media #{$medium-up}
			{
			margin: rem-calc(0 70);
			}
			
			&:first-child{margin-left:0;}
			&:last-child{margin-right:0;}
		}
		
		
		
		a{	
			display:block;
			position:relative;
			width:rem-calc(96);
			height:rem-calc(96);
			border-radius:50%;
			background-color:#FFF;
			&:hover {
				opacity: .8;
			}
			
			img{
				position:absolute;
				margin:0 auto;
				left:0;
				right:0;
				top:50%;
				transform: translateY(-50%);
			}
			
		}
	}
	
	
	.footer2{
	
		text-align:left;
		background-color:#f2f2f2;
		padding: rem-calc(85) 0;
		address {
			text-align:center;
			font-style:normal;
			margin-bottom: rem-calc(75);
			
			
			img {
				margin-bottom:1.5em;			
			}
			
			
			@media #{$medium-up}
			{
				text-align:left;
				
				img{
					float:left;
					margin-right:rem-calc(60);

				}
			}
			
									
			
			


				

				
			
			
			.txt {overflow:hidden;}
			
		}
	}
	
	
	ul{
		list-style:none;
		
		li {
			display:inline-block;
		}
	}
	
	.main-footer-2{
	
		padding: rem-calc(45 0 10);
		@media #{$medium-up}
			{
			padding: rem-calc(45 0 100);
			}
		.site-nav{
			font-size:rem-calc(14);
			font-weight:bold;
			padding-bottom: rem-calc(40);
			border-bottom:1px solid #d3d3d3;
			margin: rem-calc(45 0 30);
			
			li {
				margin-right:30px;
				
				&:last-child{
					margin-right:0;
				}
			}
			
			
			a{
				color:#000;
				font-weight:bold;
				text-transform:uppercase;
				&:hover  {
					text-decoration: underline;
				}
			}
		}
		
		
		.legal{
			font-size:rem-calc(14);
			
			li {
				color:#005daf;
				
				&:after{
					content:'|';
					display:inline-block;
					margin:0 0.5em;
				}
				
				&:last-child{
					margin-right:0;
					
					&:after{
						content:none;
					}
				}
			}
			
			a{
				color:#005daf;	
				&:hover {
					text-decoration: underline;
				}		
			}
		
		
		
		}
		
		
		
		
	
	
	}
	
		
	
}
