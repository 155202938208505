.home {

  .about,
  .news {
    h2 {
      font-size: rem-calc(20);
      margin-bottom: rem-calc(28);
      text-transform: uppercase;
      text-align: center;
      margin-top: 1rem;

      @media #{$small-up} {
        font-size: rem-calc(25);
      }

      @media #{$medium-up} {
        font-size: rem-calc(29);
      }

      @media #{$large-up} {
        font-size: rem-calc(32);
      }
    }
  }

  .more-news {
    text-align: center;
    margin-bottom: rem-calc(54);
    font-size: rem-calc(18);
  }

  @media #{$medium-up} {
    .news {
      h2 {
        margin-top: 3rem;
      }
    }
  }



  article {
    margin-bottom: 2rem;

    h3 {
      border: 0;
      padding: 0;
      font-size: rem-calc(23);

      @media #{$medium-up} {
        font-size: rem-calc(25);
      }

      @media #{$large-up} {
        font-size: rem-calc(24);
      }
    }

    img {
      margin-bottom: rem-calc(15);
    }
  }

  .read-more {
    background: $primary-color;
  }

  @media #{$small-up} {
    .news-main {
      @include grid-row($behavior: nest);
    }

    .news-img {
      @include grid-column($columns: 4, $push: 8);
    }

    .news-txt {
      @include grid-column($columns: 8, $pull: 4);
    }
  }

  @media #{$large-up} {
    .news-container {
      @include grid-row($behavior: nest);
    }

    article {
       @include grid-column($columns: 6);
    }
  }
}

.about {
  text-align: center;
}


.banner_portada{
	position:relative;
	height:100vh;

	min-height:rem-calc(500);
	background-size:cover;
	background-position: center center;
	
	text-align:center;
	
	.banner_conts{
		position:absolute;
		top:55%;
		transform: translateY(-50%);
		right:0;
		left:0;
		
		.banner_titol{
			font-size:rem-calc(30);
			font-weight:bold;
			color:#FFF;
			line-height:1;
			margin-bottom:rem-calc(60);
			
			@media #{$medium-up}
			{
				font-size:rem-calc(60);
			}
			
		}
	}
}


.home .bloc_portada{
	min-height:100vh;
	padding:rem-calc(90 0);
	position:relative;
	background-color:$primary-color;
	
	&.wimage{
		color:#FFF;
		background-position:center top;
		background-size:cover;
		box-shadow:inset 0 0 0 2000px rgba(0, 0, 0, 0.3);
		@media #{$medium-up}
		{
			box-shadow: none;
		}
		h2,h3,h4 { color:#FFF;}		
		
	}
	

	
	h2{
		font-size:rem-calc(30);
		font-weight:bold;
		margin-bottom:rem-calc(60);
		text-align:center;	

		@media #{$medium-up}
		{
			font-size:rem-calc(58);
		}
		
	}
	
	.links{
		margin-top: 3.125rem;
		text-align: center;

		&.bottom{
			margin:0;
			position:absolute;
			bottom:rem-calc(60);
			left: 0;
			right: 0;
		}
	}
	

	&.bloque4 h2{
		text-transform:none;
	}

	&.bloque3{
		padding-bottom: rem-calc(180);
	}
	
	&.bloque2{
		background:url(../img/motiu-thi.svg);		
	}
	
	&.noticias{
		background-color:#FFF;
		h2{
			text-transform:none;
		}
	}
	
	
	&.quienes_somos{
		background:url(../img/foto-quienes-somos.jpg);
		background-position:center top;
		color:#FFF;
		padding-bottom: rem-calc(180);
		
		h2{
			color:#FFF;
		}
		
		.links{
			position:absolute;
			bottom:rem-calc(60);
			left: 0;
			right: 0;
			text-align:center;
		}
	}
}


