

section.galeria_fotos {

	background-color:#000;
    background-image: linear-gradient(to bottom, #272727 2%, #000000);
    padding: rem-calc(80 0);
    margin-top: rem-calc(-80);
	position:relative;
	
	
	
	.fotorama__stage{
		margin-bottom:rem-calc(40);
	}
	
	.fotorama__thumb-border{
		border-color:#FFF;
	}
	
	.fotorama__nav__frame{
		opacity:0.6;
		
		&:hover,		
		&.fotorama__active{
			opacity:1;
		}
	}
	
	.fotorama__caption__wrap{
		color:#FFF;
		background-color:rgba(0,0,0,0.5);
		padding:rem-calc(5 40);
	}
	





}