.apartat{
	position:relative;
	background-color:$primary-color;
	background-size: cover;
	background-position: center center;
	text-align:center;
	margin-bottom: rem-calc(80);
	
	.row{
		position:relative;
	}
	
	.apartat_titol{
		position:relative;
		padding:3.7rem 0;
		color:#FFF;
		font-size:rem-calc(40);
		font-weight:bold;
		text-transform:uppercase;
		line-height:1;

	}
	
	.apartat_actions {
		position:absolute;
		right:0;
		bottom:rem-calc(-30);
		
		.btn {
			margin: 0;			
			display:inline-block;
			background-color:#FFF;
			border-radius: rem-calc(30);
			padding: rem-calc(20 40);
			
			font-size: rem-calc(16);
			font-weight:bold;
			color:#000;
			text-transform:uppercase;
			text-decoration:none;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	
	&.event{
	


		background-color:#cbc469;

		.apartat_titol{
			padding: rem-calc(50) 0;
		}
		
		
		h1{
			font-size:rem-calc(40);
			font-weight:bold;
			color:#000;
		}
		
		
		
	}
	
	&.seccio{

		background-size:cover;
		background-blend-mode: multiply;
		
		.apartat_titol{
			font-size:rem-calc(30);
			padding:rem-calc(70 0);
			
			@media #{$medium-up} {
				font-size:rem-calc(60);
				padding:rem-calc(200 0);
			}
			
			
		}
		
		&:before{
			content:'';
			display:block;
			position:absolute;
			left:0;top:0;
			bottom:75%;
			right:0;
			background-image: linear-gradient(to bottom, #000000, rgba(0, 0, 0, 0));
			opacity:0.6;
			
		}

	}
	
	
	

	
	
}


body.recursos .apartat{
	background-image:url(img/recursos.jpg);
}

body.convocatorias .apartat{
	background-image:url(img/ciencia_cerca.jpg);
}

body.actividades .apartat{
	background-image:url(img/actividades.jpg);
}


.intro_text{
	overflow:hidden;
	margin-top: rem-calc(-100);
	margin-bottom: rem-calc(90);
	margin-right: rem-calc(30);
	margin-left: rem-calc(30);
	
	
	
	background-color:#FFF;
	position:relative;
	border:6px solid $primary-color;
	padding:rem-calc(65 0);
	
	box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.12);
	
	p {font-size:rem-calc(18);}
	
	& *:last-child{
		margin-bottom:0;
	}
	
	  @media #{$medium-up} {
		margin-top: rem-calc(-190);
		
		p {font-size:rem-calc(23);}
	  }
	
	  @media #{$large-up} {
		margin-right:0;
		margin-left:0;
	  }

	
}

