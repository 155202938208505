

.raw_text{

	h2{
		text-transform:none;
		text-align:left;
	}


}


ul.clearlist{
	list-style:none;
	margin:0;
	
	li{
		padding:0;
		margin:0;
	
		&:before{
			content:none;
		}
	}
}


.bloc_info{
	margin-bottom:rem-calc(90);
	
	& *:last-child{margin-bottom:0;}
	}
	
	
	
.bloc_info2{
	background-repeat:no-repeat;
	background-size:cover;
	background-color:$primary-color;
	
	font-size:rem-calc(18);

	.links{
		margin-top:rem-calc(50);
		text-align:center;
	}


}

	
.vcenter{
	position:absolute;
	top:50%;
	left:0;
	right:0;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}
	
	


	
.boto{
	display:inline-block;
	background-color:#FFF;
	font-size: rem-calc(16);
	font-weight:bold;
	text-transform:uppercase;
	text-align:center;
	text-decoration:none !important;
	border-radius:rem-calc(40);
	padding:rem-calc(15 40);
	&:hover {
		opacity: .8;
	}
	
	&.boto_v1{
		border:2px solid;
		border-color:$secondary-color;
		&:hover {
			background-color: $secondary-color;
		}
	}
	
	&.boto_v2{
		border:2px solid;
		border-color:$primary-color;
	}
	
	&.boto_v3{
		background-color:$primary-color;
		color:#000;
		&:hover {
			background-color: lighten($primary-color, 10%);
		}
	}
	
	&.boto_v4{
		background-color:#FFF;
		color:#000;
		&:hover {
			background-color: lighten($primary-color, 10%);
		}
	}
	
	&.boto_v5{
		border:3px solid;
		border-color:$primary-color;
		color:#000;
		background-color:transparent;
	}
	
}


	
	
	
.info_time{
	display:inline-block;
	text-transform:uppercase;
	font-size: rem-calc(20);
	
	.dia{
		display: inline-block;
		font-size: rem-calc(50); 
		font-weight: 700;		
		border-right: 1px solid #FFF;
		line-height: rem-calc(50);
		padding-right: rem-calc(15);
		
		@media #{$medium-up}
		{
			font-size: rem-calc(68);		
		}
		
	}
	
	.mesany{
		display:inline-block;
		
		font-weight:normal;
		line-height:1.1;
		text-align:left;
		margin-left:0.5rem;
	}
	
	.any{display:block}
		


}



	
$panel_padding: 50;
	
.grid-activitats{


	margin-bottom:rem-calc(40);
	@media #{$medium-up}
		{
			margin-bottom:rem-calc(160);	
		}

	.panel {
		position:relative;
		padding: rem-calc(20 20 160);
		background-color:#cbc469;
		background-blend-mode: multiply;
		background-size:cover;
		margin-bottom:30px;
		
		
		&.panel_2 { background-color:#84cb69; }
		&.panel_3 { background-color:#e06f9f; }
		&.panel_4 {
			background-color:#e06f9f;

		}
		&.panel_5 { background-color:#88ddcc; }
		
		
		&.panel_bg {
			color:#FFF;
			
			h3{
				color:#FFF;
			}
		
		}
		
		@media #{$medium-up}
		{
			padding: rem-calc($panel_padding $panel_padding 160);
		
		}


		
			
		.info_time{
			color:#FFF;
			margin-bottom:rem-calc(20);
		}
		
		h3{
			text-transform:uppercase;
			font-size: rem-calc(24);
			font-weight:bold;
			margin-bottom:rem-calc(25);
			
			@media #{$medium-up}
			{
				font-size: rem-calc(40);
			
			}
			
		}
		
		a{
			color:#000;
			text-decoration:none;

		}
		
		.info{
			font-size: rem-calc(20);
		}

		.links{
			text-align:center;
			margin-top:2em;

			
			@media #{$medium-up}
			{
				margin-top:0;
				position:absolute;				
				bottom:rem-calc($panel_padding);
				left:rem-calc($panel_padding);
				right:rem-calc($panel_padding);
			}
			
			
			dl{
				text-align:left;
				margin:0;
				}
			
			dd,dt{
				
				text-align:center;
				
				@media #{$medium-up}
				{
					display:inline-block;
					text-align:left;
				}
				
			}
			
			dt{
				vertical-align:middle;
				text-transform:uppercase;
				
				
				@media #{$medium-up}
				{
					width:rem-calc(120);
				}
				
			}
			
			dd{
				margin-right:rem-calc(20);

				.boto{
					padding: rem-calc(4 10);
					font-weight:normal;
					font-size: rem-calc(20);
					min-width:rem-calc(120);
				}
			}
			
			
			
				
			
		}
		
		


		
	}
		
		
	
	
}



.grid_date_events{

	.event{
		margin-bottom:rem-calc(70);


		.info_time{
			color:$secondary-color;
			margin-bottom:rem-calc(25);
			
			.dia{
				border-color:$primary-color;
			}
		}
		
		.event_type{
			color:$secondary-color;
			text-transform:uppercase;
		
		}
		
		h3{
			text-transform:uppercase;
			font-weight:bold;
			font-size:rem-calc(20);
			margin-bottom:rem-calc(15);
		}
		
		.event_info{
			padding:0;
			margin:0;
			margin-bottom:rem-calc(20);
			
			.hora{
				font-weight:bold;
			}
			
			li{
				padding-left:0;
				&:before{
					content:'';
					position:static;
					display:inline-block;
					width:14px;
					height:14px;
					background:transparent no-repeat center center;
					background-size:contain;
					
					margin-right:0.3em;
					
				}
				
				&.lloc:before{ background-image:url(img/ico-lloc-contacte.svg); }
				&.hora:before{ background-image:url(img/ico-horario.svg); }
			
			}
			
		}
		
		
		.links{
			li{
				margin-bottom:rem-calc(18);
			}
		}
		
		.boto{
			padding:rem-calc(10 20);
	
			font-size:rem-calc(14);
			color:#000;

		}
			
	}
	


}


.grid_videos{

	margin:rem-calc(25 0 90);
	
	
	.column{
		margin-bottom: rem-calc(50);
	}
	
	h3{
		font-size: rem-calc(18);
		text-transform:uppercase;
		font-weight:bold;
		margin-top: rem-calc(15);
		margin-bottom:rem-calc(20);
		
		
	}
	
}


.grid_downloads{

	margin:rem-calc(25 0 50);

	li{
		margin-bottom: rem-calc(30) !important;
	
		&:before{
			content:none !important;
		}
		
		a{		
			display:block;
			border:1px solid #cacaca;
			padding:rem-calc(20 30 20 70);
			text-decoration:none;
			color:#000;
			
			.download_titol{
				display:block;
				position:relative;
				color:#0084c8;
				font-size: rem-calc(18);
				
				&:before{
					content:'';
					display:block;
					position:absolute;
					width:rem-calc(19);
					height:rem-calc(17);
					top:rem-calc(5);
					left: rem-calc(-40);
					background:url(img/ico-download.svg) no-repeat;

				}
				
				
				
			}
			
			.download_info{
				font-size: rem-calc(14);
			}
			
			
				
				
			
			
		}

	}


}


.btnlinks_list{
	list-style:none;
	text-align:center;
	
	li{
	
		margin-bottom:rem-calc(20) !important;
	
		&:before{
			content:none !important;
		}
		
		a{
			display:block;
			padding: rem-calc(20);
			border-radius:rem-calc(40);
			background-color:#f2f2f2;
		}
		
		&.actiu{
			opacity: 0.5;
		}
		
		
	}


}



.form-body{

	padding:rem-calc(25);
	background-color:#F2F2F2;
	
	@media #{$large-up}
	{
	padding:rem-calc(65);
	
	}
	
	h3, .form_titol{
		text-transform:uppercase;		
		font-size: rem-calc(24);
		font-weight:bold;
		margin-bottom:rem-calc(50);
	}
		

	label{
		display:block;
		text-transform:uppercase;
		margin-bottom: rem-calc(10);
		
		&.error{
			color:#C00;
			font-weight:bold;
			font-size: rem-calc(12);
			text-transform:none;
		}
	}
	
	
	
	input, textarea{
		margin-bottom:rem-calc(20);
		border:0;
		background-color:#FFF;
		width:100%;
		padding: rem-calc(10);
	}
	
	textarea{
		height: rem-calc(216);
	}
	.protecciodades {
		label {
			display: inline-block;
		}
		input[type=checkbox] {
			display: inline-block;
			width: auto;
		}
	}
	
	.form_actions{
	
		margin-top: rem-calc(60);
		
	}
	
	.form-control.error{
		border:1px solid #C00;
	}
	
	
	.button{
		display:inline-block;
		font-size: rem-calc(16);
		font-weight:bold;
		text-transform:uppercase;
		text-align:center;
		text-decoration:none !important;
		border-radius:rem-calc(40);
		padding:rem-calc(15 40);
		
		border:3px solid;
		border-color:$primary-color;
		color:#000;
		background-color:transparent;
		&:hover {
			background-color: $primary-color;
		}
		
	}
	

	


}




.form_response{

		padding:rem-calc(25);
		text-align:center;
		
		@media #{$medium-up}
		{
			padding:rem-calc(65);		
		}

		h3{
			font-size:rem-calc(31);
			font-weight:normal;
			text-transform:uppercase;
			margin-bottom:rem-calc(30);			
		}
		
		.msg_info{
			margin-bottom:rem-calc(75);
		}
		
		&:before{
			content:'';
			display:inline-block;
			width:rem-calc(150);
			height:rem-calc(100);
			margin-bottom: rem-calc(50);
			
		}
		
		&.response_ko{
		
			background-color:#f15c5c;			
			&:before{
				background:url(img/form_error.svg) no-repeat center center;

			}
			
		
		}
		
		&.response_ok{
		
			background-color:#f2f2f2;			
			&:before{
				background:url(img/form_ok.svg) no-repeat center center;
			}
			
		
		}





}
	
	
	
.fitxa_info{
	.fitxa_categoria{
		color:$secondary-color;
		text-transform:uppercase;
	}
	
	h1{
		text-transform:uppercase;
		font-size: rem-calc(24);
		margin-bottom: rem-calc(60);
		
		@media #{$large-up} {
		font-size: rem-calc(35);
		}
		
	}
	
	.info_time{
		color:$secondary-color;
		margin-bottom: rem-calc(60);
		
		.dia{
			border-color: $primary-color;
		}
	}
	
	.event_info{
		background-color: #f2f2f2;
		padding: rem-calc(40 0);
		margin-bottom: rem-calc(65);

		
		ul{
			list-style:none;
			margin:0;
		}
		
		li{
			display:block;
			text-align:left;
			border-left:1px solid #000;
			padding-left: rem-calc(70);
			margin-right: rem-calc(50);
			
			@media #{$medium-up}
			{
			display:inline-block;
			
			}
			
			
			&:last-child{
				margin-right:0;
			}
			
			&:before{
				content:'';
				background:transparent center center no-repeat;
				background-size:contain;
				position:absolute;
				left: rem-calc(30);
				top:0;
				width: rem-calc(30);
				height: rem-calc(30);
			}
			
			&.lloc:before{
				background-image:url(img/ico-lloc-contacte.svg);
			}
			&.hora:before{
				background-image:url(img/ico-horario.svg);
			}
			
			
			strong{
				display:block;
				text-transform:uppercase;
			}
		}
		
		
		
		
	}
	


}



.iframe_container {
  overflow: hidden;
  // Calculated from the aspect ration of the content (in case of 16:9 it is 9/16= 0.5625)
  padding-top: 56.25%;
  position: relative;
}
 
.iframe_container iframe {
   border: 0;
   height: 100%;
   left: 0;
   position: absolute;
   top: 0;
   width: 100%;
}

.news {
	time {
		margin-bottom: rem-calc(15);
		display: block;
	}
}
