.main-nav {

  // nav mobile
  margin-bottom: 0; //$paragraph-margin-bottom;
  //padding: rem-calc(27 23);
  transition: all 0.3s ease-out;
  text-align:center;

  h2 {
    @include visually-hidden;
  }

  ul {
    list-style: none;
    font-size: rem-calc(14);
    margin: 0;
    text-transform: uppercase;

    a {
      color: $white;
      display: block;
      padding: 0.5em 0;
	  font-weight:bold;
    }
  }
  
  .js-hidden{ display:none;}
  
/*
  .js & {
    display: none;
    position: absolute;
    top: 69px;
    right: $column-gutter/2;
    z-index: 1;
    width: rem-calc(226);

    &.open {
      display: block;
    }
  }*/
  

  
  
	.menu-button {
	  margin:0;
	  float:right;
	  
	  &:active,&:hover,&:focus{
		background:transparent;
		outline:none;
		}
	  
	  @media #{$large-up} {
		display: none;
	  }
	}

	
	.site-menu{
		padding:0;
		text-align:center;
		
		li{
			border-bottom:1px solid #2F2F2F;
			
			&:last-child{border:0;}
			}

	}
	
	.menu-wrapper{
		position:absolute;
		z-index:2;
		//top:105px;
		top:65px;
		left:0;
		width:100%;
		background-color:#000;
		padding:rem-calc(20 60 60);
		
	}
	
	.menu-lang-mobile{
		text-transform:none;
		margin-bottom:1em;
		
		li{
			display:inline-block;
			padding:0 rem-calc(10);
			border-right:1px solid #333;
			
			a{
				color:#AAA;
				font-weight:normal;
				padding:0;
				}
				
			&:last-child{border:0;}
		}
	
	}
	
	.legal{
		margin-top:1em;
		border-top:1px solid #FFF;
		padding-top:1em;
		
		text-transform:none;
		
		a{font-weight:normal;}
	}
	

	// nav medium
	
  @media #{$medium-up} {	
	.menu-button{float:none;}
	
	.menu-wrapper{
		top:95px;
	}

  
  
 }

  // nav medium
  @media #{$large-up} {

    @include clearfix;
    display: block !important;

    margin-bottom: 0;
    padding: 0;
    position: static !important;
    width: auto !important;
    margin-top: 0 !important;
	
	
	.site-menu{
		display:block;
		text-align:right;
		
		li{
			border:0;
			}
		
	}

    .menu-wrapper {
	  position: static;
	  padding:0;
	  background-color:transparent;
	  
      @include grid-row;
    }

    ul {
      @include grid-column;
      position: relative;
      font-size: rem-calc(14);
	  font-weight:bold;
      float: none;

      li {
        display: inline-block;
        position: relative;
		margin-right: 1.75em;


        > a {
          color: #FFF;
          padding: .5em 0;

          &:hover,
          &.is-hovered {
			border-bottom:rem-calc(18) solid $primary-color;
          }
        }

        .home &.inici {
          display: none;
        }

        &.active {
          > a {
            background: transparent;
            border-bottom:rem-calc(18) solid $primary-color;
          }
        }

        &.has-submenu {
          > a {
            &:after {
              @include triangle(4px, $primary-color, top);
              display: inline-block;
              margin-left: 10px;
            }
          }
        }

        &.has-submenu:hover a + .submenu {
          left: 0;
        }
      }
	  
	  li:last-child {
		margin-right:0;
	  }
    }

    .submenu {
      position: absolute;
      width: rem-calc(300);
      padding: 0;
      background: $secondary-color;
      overflow: hidden;
      z-index: 10000;
      display: block;
      margin: 0;
      text-transform: none;
      left: -999999em;
      top: 49px;
      text-align: left;
      font-size: rem-calc(16);

      li {
        float: none;
        display: block;
      }

      a {
        padding: 0.75em 1.5em;
        height: auto;

        &:hover {
          text-decoration: underline;
        }
      }
    }
	
	.js-hidden{display:block;}
	
  }/**/
}

