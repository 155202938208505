

.missatge_site{
	min-height:100vh;
	padding: 20vh 0;
	background-size:cover;
	background-repeat:no-repeat;
	
	color:#FFF;
	text-align:center;
	
	h1{
		font-size:rem-calc(100);
		font-weight:100;
		color:inherit;
		
		@media #{$medium-up}
		{
			font-size:rem-calc(180);
		}
	}
	
	h2{
		font-size: rem-calc(25);
		font-weight:bold;
		margin-bottom:rem-calc(40);
		color:inherit;
		text-transform:none;
		
		@media #{$medium-up}
		{
			font-size:rem-calc(40);
		}
		
	}
	
	
	
	&.missatge_404
	{
		background-color: #242424;
		background-image: url(../css/img/imatge-404.png);
	}
	
	
}


.site-msg footer{
	margin-top:0;
}