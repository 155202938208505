.form-steps {
	.audible {
	   display: none;
	}
	.steps ul {
	    cursor: pointer;
	    display: inline-block;
	    font-size: 0px;
	    overflow: hidden;
	    border: 1px solid #ccc;
	    line-height: 1;
	    box-sizing: border-box;
	    padding-left: 0;
	    list-style: none;
        margin: 0 0 1rem 0;
	    li {
	        margin: 0;
		    display: inline-block;
		    position: relative;
		    padding: 1em 2em 1em 3em;
		    vertical-align: top;
		    background-color: #fafafa;
		    box-sizing: border-box;
		    font-size: 1rem;
		    border-right: 1px solid #fff;
		    &.last {
		      border-right: none;
		    }
		    &:before {
		      display: none;
		    }
		    &:after {
			    display: block;
			    position: absolute;
			    z-index: 2;
			    content: "";
			    top: 0.36em;
			    right: -1.2em;
			    width: 2.3em;
			    height: 2.3em;
			    background-color: #fafafa;
			    transform: rotate(-45deg);
			    border-right: 1px solid #fff;
			    border-bottom: 1px solid #fff;
			}
			&:first-child {
			    padding-left: 2.5em;
			}
			&:last-child {
			    padding-right: 2.5em;
			}
			
			&:hover,
			&:hover:after {
			    background-color: #e6f4f9;
			}
			&.done,
            &.done:after {
			     background: #9dc8e2;
			}
			&.current,
			&.current:after {
			  background-color: #007295;
			  color: #fff;
			}
			&.current a {
			  color: #fff;
			}
			
			&.disabled,
			&.disabled:after {
			  background-color: #fff;
			  color: #aaa;
			  cursor: default;
			}
						
		}
	}
	.content {
	   .title {
	       display: none;
	   } 
	}
    .actions {
        margin-top: 1em;
        ul {
	        list-style: none;
	        margin: 0;
	        li {
	           display: inline-block;
               padding-left: 0em;
               padding-right: 1em;
		        &:before {
	              display: none;
	            }
	            &.disabled {
	                display: none;
	            }
	            
	            a {
	                @include button;
	                padding: .5rem 1rem .5rem 1rem;
	                font-size: .875rem;
	            }
	        }
        }
    }
}