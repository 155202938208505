.convocatorias {

	.convocatoria_conts{
		padding-top:rem-calc(115);
		background: linear-gradient(to bottom, rgba(0,0,0,0.12) 0%,rgba(0,0,0,0) rem-calc(270));
	}

	.convocatoria_status {
		position:relative;
		background-color:#80b73e;
		
		&:after{
			content:'';
			position:absolute;
			bottom: rem-calc(-25);
			left:50%;
			margin-left: rem-calc(-25);
			
			display:block;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: rem-calc(25 25 0 25);
			border-color: #80b73e transparent transparent transparent;
		}
			
			
			
	
		h3 {
			color:#FFF;
			font-weight:bold;
			font-size: rem-calc(24);
			text-transform:uppercase;
			text-align:center;			
			padding: rem-calc(35 0);
		}

		
	}
	
	&.convo_cerrada{
		.convocatoria_status{
			background-color:#c43547;
			
			&:after{
				border-color: #c43547 transparent transparent transparent;
			}
		}
		
	
		.convo_plazo{
			color:#c43547;
			font-weight:bold;
		}
	}			


	.convocatoria_info{
	
		h2{
		
			font-size:rem-calc(24);
		
			@media #{$medium-up}
			{		
				font-size:rem-calc(32);
			}
		
		}
		
		table{
		
			margin:rem-calc(50 0);
			width:100%;
		
			td,th{
				display:block;
				padding:rem-calc(20 0);
				border-bottom:1px solid #cacaca;
				text-align:left;
			}

			
			@media #{$medium-up}
			{
				td,th{
					display:table-cell;
				}
				th{padding-right:rem-calc(100);}
			
			
			}
			
			
			
				
		}	
		
		.info_bases{
			margin: rem-calc(50 0);
		}
	
	}
	
	.convocatoria_finalistas{

		&.final{
			background-color:#f2f2f2;
			padding-top:rem-calc(50);
			margin-bottom: rem-calc(100);
			
			.grid_videos{margin-bottom:0;}
		}
	}
	
	.convocatoria_ganador{
		margin-bottom: rem-calc(50);
		
		h3{
			font-size:rem-calc(28);
			text-transform:uppercase;
			margin-bottom:rem-calc(20);
			margin-top:rem-calc(30);
		}
		
		
	}


}
