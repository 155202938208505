.main-header {
	background:#000;
	//padding: rem-calc(40) 0 rem-calc(50);
	padding: rem-calc(30) 0 rem-calc(30);
  h1 {
    margin: rem-calc(22 0 18);

    @media #{$medium-up} {
      margin: rem-calc(19 0 21);
    }

    a:hover {
      border: 0;
    }

    img {
      width: 237px;

      @media #{$small-up} {
        width: 293px;
      }

      @media #{$medium-up} {
        width: 360px;
      }

      @media #{$large-up} {
        width: 402px;
      }
    }
  }
  
  
.skip-link {
    position: absolute;
    top: 1.5rem;
    right: 100%;
    background-color: #fff;
    padding: .75rem;
    font-size: .875rem;
    text-decoration: none;
    color: #000;
}

  .lang,
  .tools {
    display: none;
  }
  
  .main-header_extras{display:none;}
  //.js-hidden{display:none;}


  @media #{$medium-up} {
    h1 {
      width: 40%;
      float: left;
    }

    .tools {
      display: block;
      width: 60%;
      float: right;
      text-align: right;
      padding-top: 38px;
    }

    .lang {
      display: inline-block;
      margin-bottom: 0;

      li {
        display: inline;

        &.active a {
          background: $secondary-color;

          &:hover {
            text-decoration: none;
            cursor: default;

            abbr {
              cursor: default;
            }
          }
        }
      }

      a {
        display: inline-block;
        padding: 0 0.5em;
        line-height: 32px;

        &:hover {
          border: 0;
          text-decoration: underline;

          abbr {
            cursor: pointer;
          }
        }
      }
    }
	
	.main-header_extras {
		display:block;
		text-align: right;
		margin-bottom:1.25rem;
		.menu-lang{
			display:inline-block;
			border-right:1px solid #FFF;
			padding-right:2rem;
			margin-right:2rem;
			}
		
		ul{
			list-style:none;
			display:inline-block;
			margin:0;
			font-size: rem-calc(14);
			}
		li{
			display:inline-block;
			margin-right:1rem;
			}
		
		li:last-child{
			margin-right:0;
		}
		
		a{
			color:#FFF;
			font-weight:bold;
      &:hover {
        opacity: .7;
      }
      &.current {
        border-bottom: 1px solid #fff;
      }
			
		}
	}

    .share {
      display: inline-block;
      margin: 0 0 0 6px;
      vertical-align: top;
      list-style: none;

      li {
        float: left;
      }

      a {
        margin: 0 0 0 12px;
        display: inline-block;
      }
    }
	

	
	
  }
  
  
	@media #{$large-up} {
		padding: rem-calc(40) 0 rem-calc(50);
		
		//.js-hidden{display:block;}
		
		
	}
  
  
  
}

.home .main-header{

	position:absolute;
	width:100%;
	z-index:1;
	background-color: transparent

}

.menu-trigger {
  position: absolute;
  top: 29px;
  right: $column-gutter/2;
  z-index: 20;
  text-transform: uppercase;
  text-align: center;
  font-size: rem-calc(12);

  &:before {
    @extend .icon-menu-small;
    content: '';
    display: block;
    margin-bottom: 3px;
  }

  @media #{$medium-up} {
    display: none;
  }
}

.site-title {
	width: 98px;
	float: left;
	margin: 0;
	@media #{$medium-up} {
		width: 128px;
		position: absolute;

		left: $column-gutter/2;
	}
	@media #{$large-up} {
		width: auto;
		position: relative;
		left: 0;
		top: 0;
		z-index: 1;

		.sticky & {
			padding-right: 0;
			border-right: 0;
		}
	}
	a {
		text-decoration: none;
		border: 0;
	}
}
