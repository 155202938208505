.news-item {
  a {
    display: block;
    background-color: #555;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: vr(12);
    padding: vr(1);
    margin-top: vr(.25);
    position: relative;
    border-bottom: 0 !important;
    @media #{$large-up} {
      min-height: vr(15);
      margin-top: 0;
      .row--last & {
        margin-top: vr(1);
      }
    }
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      height: 70%;
      width: 100%;
      background-image: linear-gradient(to bottom, #000000, rgba(0, 0, 0, 0.0));
      transition: height .2s;
    }
    &:hover {
      // background-size: 105%;
      .news-item__title {
        text-decoration: underline;
      }
      &:before {
        height: 100%;
      }
    }
  }
  &.news-item--large {
    text-align: center;
    a {
    }
  }
  &__date {

    display: block;
    margin-bottom: vr(.5);
    // z-index: 1;
    position: relative;
    .news-item & {
      color: #fff;
    }
    .block-list--news & {
      margin-bottom: vr(.25);
    }
    .news-item--large & {
      margin-top: vr(1.5);
      @media #{$medium-up} {
        margin-top: vr(3);
      }
    }
  }
  &__title {
    margin: 0;
    padding: 0 !important;
    // z-index: 1;
    position: relative;

    .news-item & {

      text-shadow: 2px 2px 2px rgba(#000,.8);
      color: #fff;
    }
    .news-item--large & {

      @media #{$medium-up} {
        width: 40%;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}
.share {
  p {
    font-size: rem-calc(14);
    text-transform: uppercase;
    font-weight: 700;
  }
  ul {
    margin-left: 0;
    li{
      padding-left:0;
      padding-right: rem-calc(30);
      display: inline-block;
      &:before {
        content: none;
      }
      a {
        &:hover {
          opacity: .7;
        }
      }
    }
  }
}