ul.pagination {
  padding-top: 2rem;
  border-top: 1px solid $gainsboro;
  text-align:center;

  li {
	display:inline-block;
	line-height:1.4;
	margin-bottom:0;
	font-size: 1rem;
	padding:0;
	
	
	&:before{
		content:none;
	}
	
	
    &:hover {
      a {
        color: $white;
		background-color: #0060ac;
      }
    }

    &.hide {
      display: none;
    }

    &:last-child {
      a {
        border-right: 0;
      }
    }

    a {
		border-bottom: none;
		background-color: #ebebeb;
		width: 1.875rem;
		height: 1.875rem;
		line-height: 1.875rem;
		display: block;
		margin: 0 .375rem .75rem;
		

      &:link:hover {
        color: $white;
        border-bottom: 0;
        text-decoration: none;
      }
    }

    &.unavailable {
      a {
        background: none;
      }
    }
	
	&.current{
		a {
			background-color: #0060ac;
			color: #fff;
		}
	}

    &.arrow {
      a {
        padding-top: 0;
        padding-bottom: 0;
        background: none;
		width:auto;
      }

      img {
        width: 29px;
      }

      span {
        display: none;
      }

      &.prev {
        a {
          padding-left: 0;
        }
      }

      &.next {
        a {
          padding-right: 0;
        }
      }
    }
  }

  @media #{$small-up} {

    li {
      a {
        font-size: rem-calc(17);
        padding-left: 0.75rem;
        padding-right:  0.75rem;
      }

      &.hide {
        display: inline-block;
      }

      &.arrow {
        margin-top: 3px;

        a {
          border-right: 0;
        }

        img {
          width: 40px;
        }

        &.prev {
          /*float: left;*/
        }

        &.next {
          /*float: right;*/
        }
      }
    }
  }

  @media #{$medium-up} {
    li {

      &.arrow {
        span {
          display: inline;
        }

        a:link {
          &:hover {
            text-decoration: underline;
            color: $body-font-color;
          }
        }
      }

      &.prev {
        img {
          margin-right: 10px;
        }
      }

      &.next {
        img {
          margin-left: 10px;
        }
      }
    }
  }
}
